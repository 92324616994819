h2, .txt-large{
  font-size: 2.368em;
  line-height: 1.2em;
  font-weight: normal;
  margin-bottom: 1.2em;
}

.project-item h2{
  margin-bottom: 0;
  user-select: none;
  cursor: pointer;
}

.project-item{
  margin-bottom: 3.2em;
}