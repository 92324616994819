.collapsible {
  cursor: pointer;
  width: 100%;
  text-align: left;
  outline: none;
  display: flex;
  padding-right: 1.563rem;
  align-items: baseline;
  @include mq(sm){
    padding-right: 6.563rem;
  }
}

.collapsed, .collapsible:hover {

}

.content {
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  transition: min-height 0.2s ease-out;
  padding-right: 1.563rem;
  p{
    text-align: left;
    @include mq(md){
      max-width: 500px;
    }
  }
  @include mq(sm){
    padding-right: 6.563rem;
  }
}

.content{
  .tech-info{
    padding-top: 3.3em;
    display: grid;
    grid-template-columns: 6em max-content;
    p{
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .project-item-text{
    margin-top: 2em;
    p{
      margin-bottom: 1em;
    }
    em{
      font-style: italic;
    }
  }
}

.cross {
  position: relative;
  width: 0.4em; /* Adjust size as needed */
  height: 0.4em; /* Adjust size as needed */
  margin-left: auto;
  top: 0em;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  cursor: pointer;
}

.collapsible.active .cross{
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.2s;
}

.cross:before, .cross:after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: black; /* Change color as needed */
}

.cross:before {
  width: 100%;
  height: 1px; /* Thickness of the horizontal line */
  top: 50%;
  left: 0;
  transform: rotate(45deg) translateY(-50%);
}

.cross:after {
  width: 1px; /* Thickness of the vertical line */
  height: 100%;
  top: 0;
  left: 50%;
  transform: rotate(45deg) translateX(-50%);
}
