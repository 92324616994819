#home .carousel .flickity-button-icon{
  display: none !important;
}

.carousel, .flickity-viewport{
  height: 100% !important;
}

.carousel .carousel-cell{
  display: none;
}
.carousel .carousel-cell:first-of-type{
  display: block;
}

.flickity-enabled .carousel-cell{
  display: block;
}


.carousel{
  overflow: hidden;
}
.carousel-cell picture, .carousel-cell img{
  display: block;
}

.carousel-cell{
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  picture, img{
    height: 100%;
    width: 100%;
  }
  img{
    object-fit: contain;
    object-position: top left;
  }
}

.flickity-button{
  height: 100% !important;
  width: 50% !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  cursor: pointer;
  position: absolute !important;
  opacity: 0.5;
  z-index: 9999;
  @include mq(md){
    height: 100% !important;
    width: calc(50% - calc(1.563rem / 2)) !important;
  }
  .flickity-button-icon{
    display: none !important;
    @include mq(md){
      display: none !important;
      top: 50% !important;
      transform: translateY(-50%);
      width: auto !important;
    }

  }
  svg{
    height: 100px !important;
    top:0 !important;
  }
  svg path{
    fill: black;
    position: absolute;
  }
}
.flickity-button:focus {
  outline: none !important;
  box-shadow: none !important;
  opacity: 1 !important;
}

.flickity-prev-next-button.next{
  left: 50%;
  cursor: e-resize;
  border-radius: 0;
  .flickity-button-icon{
    left: unset !important;
    right: 0;
    display: none;
  }
  @include mq(md){
    left: calc(50% - calc(1.563rem / 2));
  }
}

.flickity-prev-next-button.previous{
  left: 0 !important;
  cursor: w-resize;
  border-radius: 0;
  .flickity-button-icon{
    left: 0 !important;
  }
}

.slideshow-legend{
  position: absolute;
  top: -1.4rem;
  left:0;
  color: black;
  padding: 0 0.75rem;
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: center;
  @include mq(md){
    position: unset;
    display: flex;
    padding: 0;
    text-align: center;
    width: auto;
    color: green;
    left:0;
    top: unset;
  }
  a{
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: black !important;

    @include mq(md){
      display: block;
      width: max-content;
      margin-left: 1.1333333333333333em;
      color: green !important;
    }
    span{
      margin-left: auto;
      @include mq(md) {
        margin-left: 1.13em;
      }
    }
  }
  a.buy{
    span{color: red !important;}
  }
  &.has-background{
    a.buy{
      span{color: black !important;}
      @include mq(md){
        span{color: red !important;}
      }
    }
  }
}

.carousel[data-layout="1/2"]{
  .carousel-cell{
    padding: 0;
    img{
      object-fit: cover;
    }
    video{
      object-fit: cover;
    }
  }
  .flickity-button-icon{
    display: block;
  }
}

.carousel[data-total="1"]{
  .flickity-button-icon{
    display: none !important;
  }
}


// COLLAPSIBLE SLIDESHOW
.collapse-slideshow{
  height: 50vh;
  width: 100%;
  @include mq(sm){
    display: none;
  }
}
.collapse-slideshow .carousel.image-item{
  position: relative !important;
  opacity: 1;
  padding: 0;
  padding-top: 1.563rem;
}

.collapse-slideshow .carousel-cell picture img{}

.gotopage{
  height: 100%;
  width: 100%;
  display: flex;
}


@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {
  .slideshow-container{
    padding-left: calc(2.5rem);
  }
  .carousel.image-item{
    padding-bottom: 2.5em;
    padding-right: 1em;
  }

}