body#studio{

.swup-container{
  display: block;
  @include mq(sm){
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.left-text{
  padding-left: 1.563rem;
  padding-right: var(--hh);
  p{
    @extend .txt-large;
    text-align: left;
    margin-top: 0;
  }
}

.right-text{
  padding-left: 1.563rem;
  padding-bottom: 1.563rem;
  @include mq(md){
    max-width: 500px;
  }
  p{
    text-align: left;
    margin-bottom: 1em;
    em{
      font-style: italic;
    }
  }
  @include mq(sm){
    padding-left: 0;
  }

}

  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 812px)
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: landscape) {

    .left-text {
      padding-left: 2.5rem;

    }
  }

}