// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    xs: (
      fs: 2.74vw,
      lh: 1.263em,
    ),
    sm: (
      fs: 12px,
      lh: 18px,
    ),
    md: (
      fs: 15px,
      lh: 20px,
    ),
    lg: (
      fs: 19px,
      lh: 24px,
    )
  ),
);

$margin: (
  sm: 1,
);

:root {
  font-size: 16px;
  --base-space: 8px;
  --color-text: black;
  --color-link: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: mercureregular,
    fallback: "serif",
    withFile: true,
  ),
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 815px,
  md: 1024px,
  lg: 1270px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG
