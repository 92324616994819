.menu {
  padding: 1.563rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 3.75em;
  position: absolute;
  top: 0;
  background-color: white;
}

nav {
  width: 100%;
  @include mq(sm) {
    width: 50%;
  }

  ul li {
    line-height: 1.6em;
    display: inline-block;
    margin-left: 1em;
    &:first-of-type {
      margin-left: 0;
    }
    @include mq(sm) {
      line-height: 1.263em;
    }
  }
}


@media only screen
and (min-device-width: 375px)
and (max-device-width: 812px)
and (-webkit-min-device-pixel-ratio: 3)
and (orientation: landscape) {

  .menu{
    padding: 1em;
  }

}