body, html, main {
  position: fixed;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

main{
  display: flex;
  flex-direction: column;
}

.home-image{
  flex: 1;
}

.fp-watermark{
  display: none;
}

em{
  font-style: italic;
}