.logo{
  max-width: 150px;
  padding: 1.5rem;
  padding-bottom: 1.2rem;
  margin: 0 auto;
}

.home-image{
  height: 50%;
  padding: 1.7rem 1.7rem 1.4rem;
  display: flex;
  flex-direction: column;
  picture{
    display: block;
    flex: 1;
    height: 80%;
  }
  img{
    object-fit: contain;
    height: 100%;
  }
}

p{
  margin: 1.2rem 0 0;
  text-align: center;
}