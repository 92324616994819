.image-container{
  height: calc(var(--vh) * 100);
  picture, img{
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.fp-overflow{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page-container .fp-overflow{
  margin-top: var(--hh);
}

.swup-container{
}

.slideshow-container{
  display: none;
  @include mq(sm){
    display: block;
    position: fixed;
    width: 50%;
    height: calc(100% - var(--hh));
    padding-left: 1.563rem;
  }
}

.image-item{
  position: absolute !important;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  width: 100%;
  padding-bottom: var(--hh);
  padding-right: var(--hh);
  &.selected{
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
}

.data-container{
  padding-left: 1.563rem;
  @include mq(sm){
    height: 100%;
    padding-left: 50%;
  }
  .project-item{
    cursor: pointer;
  }
  .project-item[aria-disabled]{
    display: none;
  }
}